/* This is just for testing purposes */
@use "@angular/material" as mat;

@import "@zonar-ui/material/zonar-ui-material.theme";
@import "@zonar-ui/core/zonar-ui-core.theme";
@import "@zonar-ui/sidenav/zonar-ui-sidenav.theme";
@import "@zonar-ui/footer/zonar-ui-footer.theme";

@include zonar-ui-sidenav-theme($zonar-default-theme);
@include zonar-ui-core-theme($zonar-default-theme);
@include zonar-ui-footer-component-theme($zonar-default-theme);

@import "~saturn-datepicker/bundle.css";
@import "theme";

body {
	margin: 0;
}

// @include angular-material-theme($custom-theme-1);
@include mat.all-component-themes($zonar-default-theme);

// Heading 1
.heading-1 {
	color: evir-theme($color, header-1);
	font-family: evir-theme($font-family, header-1);
	font-size: 36px;
	font-weight: evir-theme($font-weight, header-1);
	line-height: 48px;
	letter-spacing: -0.7px;
}

// Heading 4
.heading-4 {
	color: evir-theme($color, color-dark);
	font-family: evir-theme($font-family, default);
	font-size: 20px;
	line-height: 32px;
	height: 27px;
}

// Overflow Menus
.overflow-menu .mat-menu-content {
	margin: 0 16px;
}

.arrow-icon {
	margin: 0 5px;
}

.thumbnail {
	border-radius: 3px;
}

// filter classes
.filter-button {
	border-radius: evir-theme($border-radius, filter-button) !important;
	font-family: evir-theme($font-family, default);
	padding-left: 12px;
	padding-right: 8px;
	min-width: fit-content;
	text-align: left;
}

.filter-popup {
	color: evir-theme($color, filter-popup);
}
.filter-button-focused {
	border: 1px evir-theme($border-color, filter-button-focused) solid !important;
}

.filter-button-not-focused {
	border: 1px evir-theme($border-color, filter-button-not-focused) solid !important;
}

.filter-button-text {
	display: inline-block;
	font-size: 16px;
	min-width: 10ch;
	text-align: left;
}

.filter-button-text-value-selected {
	color: evir-theme($color, filter-button-focused);
	font-weight: 600 !important;
	max-width: 184px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.filter-button-text-value-not-selected {
	color: evir-theme($color, filter-button-not-focused);
}

.filter-button-label {
	align-items: center;
	background: evir-theme($background-color, color-white);
	display: flex;
	font-family: evir-theme($font-family, default);
	font-size: 12px;
	height: 15px;
	justify-content: center;
	left: 10px;
	padding: 0 2px;
	position: absolute;
	top: -8px;
}

.filter-button-label-focused {
	color: evir-theme($color, filter-button-label-focused);
}

.filter-button-label-not-focused {
	color: evir-theme($color, filter-button-label-not-focused);
}

.filter-buttons-container {
	display: inline-flex;
	justify-content: flex-end;
	margin: 24px 4px 12px;
	width: calc(100% - 8px);
}

.filter-button-submit {
	background-color: evir-theme($background-color, filter-button-submit);
	border-radius: 3px;
	color: evir-theme($color, filter-button-submit);
	font-family: evir-theme($font-family, default);
	font-size: 16px;
	font-weight: evir-theme($font-weight, bold) !important;
	margin-left: 16px;
	padding: 0 16px;
}

.filter-button-cancel {
	border-radius: 3px;
	color: evir-theme($color, cancel-button);
	font-family: evir-theme($font-family, default);
	font-size: 16px;
	font-weight: evir-theme($font-weight, bold) !important;
	padding: 0 16px;
}

// Checkboxes
.mat-checkbox-frame {
	border-color: evir-theme($border-color, checkbox) !important;
}

.mat-checkbox-checked .mat-checkbox-background {
	background-color: evir-theme($background-color, checkbox-checked) !important;
}

.mat-checkbox-indeterminate .mat-checkbox-background {
	background-color: evir-theme($background-color, checkbox-indeterminate) !important;
}

.mat-checkbox-layout > .mat-checkbox-inner-container {
	width: 18px;
	height: 18px;
}

.mat-checkbox-layout > .mat-checkbox-label {
	font-family: evir-theme($font-family, default);
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
	color: evir-theme($color, paginator);
	font-family: evir-theme($font-family, default);
	font-size: evir-theme($font-size, paginator);
}

// tooltips
.mat-tooltip {
	background: evir-theme($background-color, color-gray);
	color: evir-theme($color, color-white);
	font-family: evir-theme($font-family, default);
	font-size: 14px !important;
	font-weight: 600;
	margin: 0;
	padding: 8px;
	text-align: center;
}

// action buttons
.action-button {
	font-family: evir-theme($font-family, default);
	font-weight: 700;
}

// supress margins to allow more explicit control of alignment
.mat-button-base {
	margin: 0 !important;
}
.action-button-primary {
	background-color: evir-theme($background-color, action-button);
	border: 1px solid evir-theme($border-color, action-button);
	color: evir-theme($color, action-button);

	&:disabled {
		background-color: evir-theme($background-color, color-ghost);
		border: 1px solid evir-theme($border-color, color-ghost);
		color: evir-theme($color, color-slate);
		cursor: pointer;
	}
}

.action-button-secondary {
	border: 1px solid evir-theme($border-color, color-steel);
	color: evir-theme($color, color-blue);

	&:focus:enabled {
		border: 1px solid evir-theme($border-color, color-steel);
		color: evir-theme($color, color-blue);
	}

	&:disabled {
		color: evir-theme($color, color-slate);
		cursor: pointer;
	}
}

.action-button-tertiary {
	background-color: evir-theme($background-color, color-yellow);
	border: 1px solid evir-theme($border-color, color-yellow);
	color: evir-theme($color, color-dark);

	&:focus:enabled {
		border: 1px solid evir-theme($border-color, color-yellow);
		color: evir-theme($color, color-dark);
	}

	&:disabled {
		color: evir-theme($color, color-slate);
		cursor: pointer;
	}
}

.mat-button-focus-overlay {
	background-color: transparent !important;
}

// radio buttons
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: evir-theme($border-color, radio-button);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
	background-color: evir-theme($background-color, radio-button);
	color: evir-theme($color, radio-button);
}

.error-message {
	font-family: evir-theme($font-family, default);
	font-size: 14px;
	margin: 10px;
}

// tables
.mat-table {
	font-family: evir-theme($font-family, default);
	width: 100%;
}

.mat-header-cell {
	border-bottom: 2px solid evir-theme($border-color, table-header-cell) !important;
	color: evir-theme($color, mat-header-cell);
	font-size: evir-theme($font-size, table-header-cell);
	font-weight: 400;
	line-height: evir-theme($line-height, mat-header-cell);
	padding: 0 9px !important;
}

.mat-cell {
	border-bottom: 1px solid evir-theme($border-color, table-cell) !important;
	color: evir-theme($color, cell);
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	padding: 0 9px !important;
}

.mat-column-checkbox {
	flex: 0 0 18px;
	width: 18px;
}

.mat-expansion-panel-header {
	font-weight: 400 !important;
}
.mat-sort-header-button {
	color: evir-theme($color, table-sort-header-button) !important;
	font-family: evir-theme($font-family, default);
	line-height: evir-theme($line-height, mat-header-cell);
	text-align: left;
}

.mat-sort-header-sorted {
	color: evir-theme($color, table-sort-header-sorted);
	font-family: evir-theme($font-family, default);
	font-weight: evir-theme($font-weight, table-sort-header-sorted);
}

.mat-sort-header-arrow {
	color: evir-theme($color, table-sort-header-arrow);
	font-family: evir-theme($font-family, default);
	margin: 2px;
}

.mat-sort-header-content {
	text-align: evir-theme($text-align, mat-sort-header-content);
}

// print view
@media print {
	.pagebreak {
		page-break-before: always;
	} /* page-break-after works, as well */
}

// autocomplete
.mat-option {
	color: evir-theme($color, color-dark);
	font-family: evir-theme($font-family, default);
	font-size: 14px;
	height: 36px;
	line-height: 24px;

	&:hover {
		background-color: evir-theme($background-color, color-blue-2) !important;
	}
}

.mat-option.mat-active {
	background-color: evir-theme($background-color, color-blue-2) !important;
}

.mat-optgroup {
	> label {
		color: evir-theme($color, color-gray);
		font-family: evir-theme($font-family, default);
		font-size: 12px;
		height: 26px;
		line-height: 16px;
		margin-bottom: -8px;
		padding-top: 13px;
	}
}

// misc
.logo {
	height: 100vh;

	a {
		text-decoration: none;

		img {
			position: absolute;
			bottom: 60px;
			right: 60px;
		}
	}
}

.pointer {
	cursor: pointer;
}

.bold,
strong {
	font-weight: bold;
}

// snackbar styling
.app-snackbar-error {
	background-color: evir-theme($background-color, color-red);
}

// Material font-weights
.mat-toolbar,
.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab,
.mat-menu-panel,
.mat-calendar-body-label,
.mat-calendar-period-button {
	font-weight: evir-theme($font-weight, material);
}

// TODO: Remove this whole block once we get rid of saturn datepicker
.mat-calendar-body-cell {
	position: relative !important;
	&-container & {
		position: absolute !important;
	}
}

// Hide month label from calendar
.mat-calendar-body-label {
	opacity: 0 !important;
}

// Hide month label from calendar
.mat-calendar-body-label[colspan="7"] {
	display: none !important;
}

// Hide calendar divider
.mat-calendar-table-header-divider {
	display: none !important;
}

// fix style conflict from PL theme
.mat-button-wrapper {
	font-weight: 400;
}

zui-footer-component {
	background-color: evir-theme($background-color, page);
}

.mat-sidenav.collapsed-state {
	.menu-icon {
		margin-left: 0 !important;
	}

	.mat-button-wrapper {
		margin: 0;
	}
}
